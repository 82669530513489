import { Routes, useRoutes } from "react-router-dom";
import HomeRoute from "./routes/Home";
import OrderRoute from "./routes/Order";
import UsersRoute from "./routes/User";
import UnverifiedRoute from "./routes/UnverifiedRoute";
import ProductRoute from "./routes/Product";
import CategoryRoute from "./routes/Category";
import SettingsRoute from "./routes/Settings";
import MemberRoute from "./routes/Member";
import PromotionRoute from "./routes/Promotion";
import PageNotFoundRoute from "./routes/PageNotFound";
import PosRoute from "./routes/Pos";
import StoreRoute from "./routes/Store";
import InventoryRoute from "./routes/Inventory";
import PayemntRoute from "./routes/Payment";
import TestRoute from "./routes/Test";
import ReportRoute from "./routes/Report";
import BookingRoute from "./routes/Booking";
import TableRoute from "./routes/Table";

export default function Router() {
  const routes = useRoutes([
    HomeRoute,
    ReportRoute,
    OrderRoute,
    PosRoute,
    UsersRoute,
    UnverifiedRoute,
    ProductRoute,
    CategoryRoute,
    SettingsRoute,
    MemberRoute,
    PromotionRoute,
    PageNotFoundRoute,
    StoreRoute,
    InventoryRoute,
    PayemntRoute,
    BookingRoute,
    TableRoute,
    TestRoute,
  ]);

  return routes;
}
