import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  TextField,
  Grid,
  Box,
  Menu,
  MenuItem,
  Autocomplete,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation, Translation } from "react-i18next";
import { ValidationGroup, Validate } from "mui-validate";
import { useNavigate } from "react-router-dom";

// permission
import { useSelector } from "react-redux";
import { user } from "../../reducers/userSlice";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";

export default function InventoryInfo({ inventory, setInventory }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {}, []);

  useEffect(() => {
    const deletePermission = checkPermission(
      currentUser,
      currentPage,
      "delete"
    );

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const goToManagement = () => {
    console.log(inventory);
    navigate(`/inventory/management?inventory_id=${inventory.id}`);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          inventory.id
            ? t("inventory.edit_inventory")
            : t("inventory.new_inventory")
        }
        action={
          haveDeletePermission && (
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                goToManagement();
              }}
            >
              {t("inventory.inventory_management")}
            </Button>
          )
        }
      />

      <CardContent>
        <ValidationGroup initialValidation="silent">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Validate
                name="name"
                required={[true, t("error.please_fill_in_this_field")]}
              >
                <TextField
                  size="small"
                  value={inventory.name}
                  onChange={(event) =>
                    setInventory({ ...inventory, name: event.target.value })
                  }
                  required
                  fullWidth
                  autoFocus
                  label={t("layout.name")}
                />
              </Validate>
            </Grid>
          </Grid>
        </ValidationGroup>
      </CardContent>

      <CardActions
        sx={{ justifyContent: "space-between", px: 2 }}
      ></CardActions>
    </Card>
  );
}
