import { useState } from "react";
import { Box, Menu, MenuItem, IconButton } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next"; // Functional Components
import { setLocalStorageLocale } from "../helper/LocalStorageHelper";

export default function LanguageDropdown() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageList] = useState([
    { name: "English", value: "en" },
    { name: "繁體中文", value: "zh-HK" },
  ]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchLang = (value) => {
    i18n.changeLanguage(value);
    setLocalStorageLocale(value);
    handleClose();
  };

  return (
    <Box component={"div"}>
      <IconButton
        size="large"
        aria-label="switch language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languageList.map((lang) => (
          <MenuItem key={lang.value} onClick={() => switchLang(lang.value)}>
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
