import loadable from "@react-loadable/revised";
import DashboardLayout from "../components/DashboardLayout";
import Loading from "../components/Loading";

const TestList = loadable({
  loader: () => import("../views/test/Index"),
  loading: Loading,
});
const LoadingTest = loadable({
  loader: () => import("../views/test/LoadingTest"),
  loading: Loading,
});
const PrintInvoice = loadable({
  loader: () => import("../views/test/PrintInvoice"),
  loading: Loading,
});
const PromotionTest = loadable({
  loader: () => import("../views/test/PromotionTest"),
  loading: Loading,
});
const CalendarTest = loadable({
  loader: () => import("../views/test/CalendarTest"),
  loading: Loading,
});

const TestRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/tests",
      element: <TestList />,
    },
    {
      path: "/tests/print_invoice",
      element: <PrintInvoice />,
    },
    {
      path: "/tests/loading_test",
      element: <LoadingTest />,
    },
    {
      path: "/tests/promotions",
      element: <PromotionTest />,
    },
    {
      path: "/tests/calendar",
      element: <CalendarTest />,
    },
  ],
};

export default TestRoute;
