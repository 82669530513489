import { useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useSelector, useDispatch } from "react-redux";
import { userId, username } from "../reducers/userSlice";
import { useNavigate, Link } from "react-router-dom";
import { removeLocalStorageUser } from "../helper/LocalStorageHelper";
import { useTranslation, Translation } from "react-i18next";
import { UserApi } from "../api/UserApi";

export default function LanguageDropdown() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUserId = useSelector(userId);
  const currentUsername = useSelector(username);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    removeLocalStorageUser();
    await UserApi.logout();
    window.location.href = "/login";
    handleClose();
  };

  return (
    <Box component={"div"}>
      <Button onClick={handleMenu} color="inherit">
        <AccountCircle />
        {currentUsername}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={`/users/${currentUserId}`}
          onClick={handleClose}
        >
          <Translation>
            {(t, { i18n }) => <Typography>{t("layout.profile")}</Typography>}
          </Translation>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Translation>
            {(t, { i18n }) => <Typography>{t("layout.logout")}</Typography>}
          </Translation>
        </MenuItem>
      </Menu>
    </Box>
  );
}
