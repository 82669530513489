import loadable from "@react-loadable/revised";
import { Navigate } from "react-router-dom";
import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import Loading from "../components/Loading";
import { getReportPageName } from "../helper/GlobalValueHelper";

const Report = loadable({
  loader: () => import("../views/report/List"),
  loading: Loading,
});

const ReportRoute = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "/report",
      element: (
        <AuthProvider page={getReportPageName()} action={"view"}>
          <Report />
        </AuthProvider>
      ),
    },
  ],
};

export default ReportRoute;
