import { useEffect, useState } from "react";
import { Container, Box, Breadcrumbs, Typography } from "@mui/material";
import axios from "../../helper/AxiosHelper";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation, Translation } from "react-i18next";
import * as CategoryApi from "../../api/CategoryApi";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import PaymentMethodInfo from "../../components/payment/PaymentMethodInfo";
import { AgreementApi } from "../../api/AgreementApi";
import AgreementInfo from "../../components/agreement/AgreementInfo";

export default function AgreementEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [agreement, setAgreement] = useState({
    name: "",
    consentFormPdf: null,
    consentForm: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await AgreementApi.details(id);
      setAgreement(response);
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async () => {
    try {
      setFullScreenLoading(true);

      if (!agreement.name) {
        toast.error(t("layout.please_enter_all_required_fields"));
        setFullScreenLoading(false);
        return;
      }

      const success = await AgreementApi.update(id, agreement);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }

      setFullScreenLoading(false);
    } catch (error) {
      setFullScreenLoading(false);
      console.log(error);
    }
  };

  const deleteAgreement = async () => {
    try {
      setFullScreenLoading(true);
      const success = await AgreementApi.deleteAgreement(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/settings/agreement");
      }
      setFullScreenLoading(false);
    } catch (error) {
      setFullScreenLoading(false);
      console.log(error);
    }
  };

  const onCancel = async () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={"/payment-method"}>{t("menu.settings")}</Link>
        <Link to={"/settings/agreement"}>{t("settings.agreement")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <AgreementInfo
          agreement={agreement}
          setAgreement={setAgreement}
          deleteAgreement={deleteAgreement}
        />
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={onSubmit} />
    </Container>
  );
}
