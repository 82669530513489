import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import AuthProvider from "../components/AuthProvider";
import Management from "../views/inventory/Management";
import { getInventoryPageName } from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";

const List = loadable({
  loader: () => import("../views/inventory/List"),
  loading: Loading,
});
const Edit = loadable({
  loader: () => import("../views/inventory/Edit"),
  loading: Loading,
});
const Create = loadable({
  loader: () => import("../views/inventory/Create"),
  loading: Loading,
});

const InventoryRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/inventory",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/inventory/:id",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"view"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/inventory/create",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/inventory/management",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"update"}>
          <Management />
        </AuthProvider>
      ),
    },
  ],
};

export default InventoryRoute;
