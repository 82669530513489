import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import hk from "./zh-HK.json";

const resources = {
  en: {
    translation: en,
  },
  "zh-HK": {
    translation: hk,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  resources,
});

export default i18n;
