import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      "label + &": {
        // marginTop: "1.5rem",
        color: Palette.text.secondary,
      },
      "& .MuiInputBase-input": {
        // padding: "0.6rem 12px",
      },
    },
  },
};

export default Element;
