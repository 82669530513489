import * as React from "react";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";

export default function PageNotFound() {
  return (
    <Container maxWidth="false">
      <Box
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "calc(100vh - 128px)",
        }}
      >
        Page Not Found
      </Box>
    </Container>
  );
}
