import axios from "../helper/AxiosHelper";

export const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/category/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const details = async (id) => {
  const response = await axios.get(`category/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    description: result.record.description,
    images: result.record.images,
    priority: result.record.priority,
    showOnPos: result.record.show_on_pos,
    showOnOrdering: result.record.show_on_ordering,
  };
};

export const create = async (category) => {
  const data = {
    name: category.name,
    description: category.description,
    images: category.images,
    priority: category.priority,
    show_on_pos: category.showOnPos,
    show_on_ordering: category.showOnOrdering,
  };

  const response = await axios.post(`category/create`, data);

  return response.data.result.insert_id;
};

export const update = async (id, category) => {
  const data = {
    name: category.name,
    description: category.description,
    images: category.images,
    priority: category.priority,
    show_on_pos: category.showOnPos,
    show_on_ordering: category.showOnOrdering,
  };

  const response = await axios.post(`category/update/${id}`, data);
  return response.data.success;
};

export const deleteCategory = async (id) => {
  const response = await axios.post(`category/delete/${id}`);
  return response.data.success;
};

export const fullList = async () => {
  const response = await axios.get(`/category?fields=raw`);
  const result = response.data.result;

  return result.map((data) => {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      updatedAt: data.updated_at,
    };
  });
};
