import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import DashboardLayout from "../components/DashboardLayout";
import AuthProvider from "../components/AuthProvider";
import {
  getProductAddonPageName,
  getProductBarcodePageName,
  getProductOptionPageName,
  getProductOptionValuePageName,
  getProductPageName,
} from "../helper/GlobalValueHelper";

const ProductList = loadable({
  loader: () => import("../views/product/List"),
  loading: Loading,
});
const ProductEdit = loadable({
  loader: () => import("../views/product/Edit"),
  loading: Loading,
});
const ProductCreate = loadable({
  loader: () => import("../views/product/Create"),
  loading: Loading,
});

const OptionList = loadable({
  loader: () => import("../views/product/option/List"),
  loading: Loading,
});
const OptionEdit = loadable({
  loader: () => import("../views/product/option/Edit"),
  loading: Loading,
});
const OptionCreate = loadable({
  loader: () => import("../views/product/option/Create"),
  loading: Loading,
});

const ProductOptionValueList = loadable({
  loader: () => import("../views/product/option/value/List"),
  loading: Loading,
});
const ProductOptionValueEdit = loadable({
  loader: () => import("../views/product/option/value/Edit"),
  loading: Loading,
});
const ProductOptionValueCreate = loadable({
  loader: () => import("../views/product/option/value/Create"),
  loading: Loading,
});
const ProductBarcodeList = loadable({
  loader: () => import("../views/product/barcode/List"),
  loading: Loading,
});
const AddonList = loadable({
  loader: () => import("../views/product/option/value/addon/List"),
  loading: Loading,
});
const AddonEdit = loadable({
  loader: () => import("../views/product/option/value/addon/Edit"),
  loading: Loading,
});
const AddonCreate = loadable({
  loader: () => import("../views/product/option/value/addon/Create"),
  loading: Loading,
});

const ProductRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/products",
      element: (
        <AuthProvider page={getProductPageName()} action={"view"}>
          <ProductList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/:id",
      element: (
        <AuthProvider page={getProductPageName()} action={"view"}>
          <ProductEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/products/create",
      element: (
        <AuthProvider page={getProductPageName()} action={"create"}>
          <ProductCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options",
      element: (
        <AuthProvider page={getProductOptionPageName()} action={"view"}>
          <OptionList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/create",
      element: (
        <AuthProvider page={getProductOptionPageName()} action={"create"}>
          <OptionCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/:id",
      element: (
        <AuthProvider page={getProductOptionPageName()} action={"view"}>
          <OptionEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/values",
      element: (
        <AuthProvider page={getProductOptionValuePageName()} action={"view"}>
          <ProductOptionValueList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/values/create",
      element: (
        <AuthProvider page={getProductOptionValuePageName()} action={"create"}>
          <ProductOptionValueCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/values/:id",
      element: (
        <AuthProvider page={getProductOptionValuePageName()} action={"view"}>
          <ProductOptionValueEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/products/barcode",
      element: (
        <AuthProvider page={getProductPageName()} action={"barcode"}>
          <ProductBarcodeList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/addons",
      element: (
        <AuthProvider page={getProductAddonPageName()} action={"view"}>
          <AddonList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/addons/create",
      element: (
        <AuthProvider page={getProductAddonPageName()} action={"create"}>
          <AddonCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/addons/:id",
      element: (
        <AuthProvider page={getProductAddonPageName()} action={"view"}>
          <AddonEdit />
        </AuthProvider>
      ),
    },
  ],
};

export default ProductRoute;
