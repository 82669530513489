import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/serviceAgreement/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async (id) => {
  const response = await axios.get(`serviceAgreement/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    consentForm: result.record.consent_form,
  };
};

const create = async (agreement) => {
  const data = {
    name: agreement.name,
    consent_form_pdf: agreement.consentFormPdf,
  };

  const response = await axios.post(`serviceAgreement/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, agreement) => {
  const data = {
    name: agreement.name,
    consent_form_pdf: agreement.consentFormPdf || undefined,
  };

  const response = await axios.post(`serviceAgreement/update/${id}`, data);
  return response.data.success;
};

const deleteAgreement = async (id) => {
  const response = await axios.post(`serviceAgreement/delete/${id}`);
  return response.data.success;
};

export const AgreementApi = {
  list,
  details,
  create,
  update,
  deleteAgreement,
};
