import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  originTotal: 0,
  finalTotal: 0,
  discountPrice: 0,
  paidPrice: 0,
  unpaidPrice: 0,
  adjustment: 0,
  orderPromotion: {},
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    updateCartState: (state, value) => {
      state.cartItems = value.payload.cartItems;
      state.originTotal = parseFloat(value.payload.originTotal);
      state.finalTotal = parseFloat(value.payload.finalTotal);
      state.discountPrice = parseFloat(value.payload.discountPrice);
      state.orderPromotion = value.payload.orderPromotion;
    },
    updateCartItems: (state, value) => {
      state.cartItems = value.payload;
    },
    updatePaidPrice: (state, value) => {
      state.paidPrice = parseFloat(value.payload);
    },
    updateUnpaidPrice: (state, value) => {
      state.unpaidPrice = parseFloat(value.payload);
    },
    updateFinalTotal: (state, value) => {
      state.finalTotal = parseFloat(value.payload);
    },
    updateOriginTotal: (state, value) => {
      state.originTotal = parseFloat(value.payload);
    },
    udpateAdjustment: (state, value) => {
      state.adjustment = parseFloat(value.payload);
    },
  },
});

export const {
  updateCartState,
  updatePaidPrice,
  updateUnpaidPrice,
  updateFinalTotal,
  udpateAdjustment,
  updateCartItems,
  updateOriginTotal,
} = shoppingCartSlice.actions;

export const cartItemsState = (state) => state.shoppingCart.cartItems;

export const originTotalState = (state) => state.shoppingCart.originTotal;

export const finalTotalState = (state) => state.shoppingCart.finalTotal;

export const discountPriceState = (state) => state.shoppingCart.discountPrice;

export const orderPromotionState = (state) => state.shoppingCart.orderPromotion;

export const paidPriceState = (state) => state.shoppingCart.paidPrice;

export const unpaidPriceState = (state) => state.shoppingCart.unpaidPrice;

export const adjustmentState = (state) => state.shoppingCart.adjustment;

export default shoppingCartSlice.reducer;
