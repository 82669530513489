import loadable from "@react-loadable/revised";
import AuthProvider from "../components/AuthProvider";
import {
  getCategoryPageName,
  getPaymentMethodPageName,
} from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";
import Loading from "../components/Loading";

const PaymentList = loadable({
  loader: () => import("../views/payment/List"),
  loading: Loading,
});
const PaymentCreate = loadable({
  loader: () => import("../views/payment/Create"),
  loading: Loading,
});
const PaymentEdit = loadable({
  loader: () => import("../views/payment/Edit"),
  loading: Loading,
});

const PaymentRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/payment-method",
      element: (
        <AuthProvider page={getPaymentMethodPageName()} action={"view"}>
          <PaymentList />
        </AuthProvider>
      ),
    },
    {
      path: "/payment-method/create",
      element: (
        <AuthProvider page={getPaymentMethodPageName()} action={"create"}>
          <PaymentCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/payment-method/:id",
      element: (
        <AuthProvider page={getPaymentMethodPageName()} action={"view"}>
          <PaymentEdit />
        </AuthProvider>
      ),
    },
  ],
};

export default PaymentRoute;
