import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Loading(props) {
  const { t } = useTranslation();
  if (props.error) {
    return <Box>Error! Component failed to load</Box>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 2,
        flex: 1,
        gap: 1,
      }}
    >
      <CircularProgress />
      <Typography>{t("layout.initializing")}</Typography>
    </Box>
  );
}
