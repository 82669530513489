import loadable from "@react-loadable/revised";
import { Navigate } from "react-router-dom";
import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import Loading from "../components/Loading";
import { getDashboardPageName } from "../helper/GlobalValueHelper";

const Home = loadable({
  loader: () => import("../views/Dashboard"),
  loading: Loading,
});

const HomeRoute = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    { element: <Navigate to="/dashboard" />, index: true },
    {
      path: "/dashboard",
      element: (
        <AuthProvider page={getDashboardPageName()} action={"view"}>
          <Home />
        </AuthProvider>
      ),
    },
  ],
};

export default HomeRoute;
