import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import AuthProvider from "../components/AuthProvider";
import { getOrderPageName } from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";

const OrderList = loadable({
  loader: () => import("../views/order/List"),
  loading: Loading,
});
const OrderEdit = loadable({
  loader: () => import("../views/order/Detail"),
  loading: Loading,
});

const OrderRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/orders",
      element: (
        <AuthProvider page={getOrderPageName()} action={"view"}>
          <OrderList />
        </AuthProvider>
      ),
    },
    {
      path: "/orders/:id",
      element: (
        <AuthProvider page={getOrderPageName()} action={"view"}>
          <OrderEdit />
        </AuthProvider>
      ),
    },
  ],
};

export default OrderRoute;
