export const getNormalProductType = () => {
  return "normal";
};
export const getServiceProductType = () => {
  return "service";
};

// page name start
export const getDashboardPageName = () => {
  return "dashboard";
};
export const getReportPageName = () => {
  return "report";
};
export const getBookingPageName = () => {
  return "booking";
};
export const getOrderPageName = () => {
  return "order";
};
export const getProductPageName = () => {
  return "product";
};
export const getProductOptionPageName = () => {
  return "productOption";
};
export const getProductOptionValuePageName = () => {
  return "productOptionValue";
};
export const getProductAddonPageName = () => {
  return "productAddon";
};
export const getProductBarcodePageName = () => {
  return "productBarcode";
};
export const getStorePageName = () => {
  return "store";
};
export const getCategoryPageName = () => {
  return "category";
};
export const getMemberPageName = () => {
  return "member";
};
export const getPromotionPageName = () => {
  return "promotion";
};
export const getInventoryPageName = () => {
  return "inventory";
};
export const getUserPageName = () => {
  return "user";
};
export const getSettingsPageName = () => {
  return "settings";
};
export const getDeviceLockPageName = () => {
  return "deviceLock";
};
export const getPosPageName = () => {
  return "pos";
};
export const getPaymentMethodPageName = () => {
  return "paymentMethod";
};
export const getAgreementPageName = () => {
  return "agreement";
};
export const getTablePageName = () => {
  return "table";
};
// page name end

// promotion type
export const getBuyXPayYPromotionType = () => {
  return "buy_x_pay_y";
};

export const getBuyItemXGetDiscountYPromotionType = () => {
  return "buy_item_x_get_discount_y";
};

export const getBuyAmountGetDiscountYPromotionType = () => {
  return "buy_amount_get_discount_y";
};

export const getExtraItemPurchaseDiscountPromotionType = () => {
  return "extra_item_purchase_discount";
};

export const getOrderRedemptionOfferPromotionType = () => {
  return "purchase_xy_get_z_discount";
};
