import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Card,
  CardHeader,
  CardContent,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Chip,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation, Translation } from "react-i18next";
import * as InventoryApi from "../../api/InventoryApi";
import * as ProductApi from "../../api/ProductApi";
import { getVariantName } from "../../helper/UnitsHelper";
import { useSearchParams } from "react-router-dom";

// components
import InventoryInfo from "../../components/inventory/InventoryInfo";
import CategorySkeleton from "../../components/category/CategorySkeleton";
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";

import SearchIcon from "@mui/icons-material/Search";

export default function InventoryManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [filter, setFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [selectdProduct, setSelectdProduct] = useState();
  const [selectedInventory, setSelectedInventory] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const stockActions = [
    { label: t("inventory.add"), value: "add" },
    { label: t("inventory.remove"), value: "remove" },
  ];

  useEffect(() => {
    fetchInventory();
  }, []);

  useEffect(() => {
    if (selectedInventory && filter.length > 0) {
      fetchProducts();
    }
  }, [filter, selectedInventory]);

  const onChangeSearchField = (value) => {
    setSearchStr(value);
  };

  const search = async () => {
    setFilter([
      { field: "title", value: searchStr },
      { field: "inventories", value: selectedInventory.id },
    ]);
  };

  const fetchProducts = async () => {
    try {
      setFullScreenLoading(true);
      const response = await InventoryApi.stockQuery(
        selectedInventory.id,
        1,
        -1,
        filter
      );
      setProducts(response.data);
      setFullScreenLoading(false);
    } catch (error) {
      setFullScreenLoading(false);
      console.log(error);
    }
  };

  const fetchInventory = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryApi.list();
      setInventoryList(response.data);
      setIsLoading(false);

      if (searchParams.get("inventory_id")) {
        const inventoryId = parseInt(searchParams.get("inventory_id"));
        const inventory = response.data.find(
          (inventory) => inventory.id === inventoryId
        );

        if (inventory) {
          setSelectedInventory(inventory);
        }

        console.log(inventory);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSelectdProduct = (value) => {
    console.log(value);
    value.variants = value.variants.map((variant) => {
      return {
        ...variant,
        action: "add",
        qtyChange: 0,
      };
    });
    console.log(value);
    setSelectdProduct(value);
  };

  const cancel = () => {
    navigate(-1);
  };

  const submit = () => {
    console.log(selectdProduct);

    if (selectedInventory && selectdProduct) {
      let data = {
        inventoryId: selectedInventory.id,
        changes: selectdProduct.variants
          .map((variant) => {
            return {
              variantId: variant.id,
              qtyChange:
                variant.action == "add"
                  ? parseInt(variant.change || 0)
                  : parseInt(variant.change || 0) * -1,
              remark: variant.remark,
            };
          })
          .filter((d) => d.qtyChange != 0),
      };

      if (data.changes.length <= 0) {
        return;
      }

      const success = InventoryApi.stockBulkChange(data);

      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchProducts();
        reset();
      }
    } else {
      toast.error("Please select a product and inventory");
    }
  };

  const reset = () => {
    setSelectdProduct();
  };

  if (isLoading) {
    return <CategorySkeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Card variant="outlined">
        <CardHeader title={t("inventory.inventory_management")} />
        <CardContent>
          <Box sx={{ p: 1 }}>
            <Autocomplete
              size="small"
              disableClearable={true}
              options={inventoryList}
              value={selectedInventory || null}
              getOptionLabel={(option) => {
                return option.name;
              }}
              filterSelectedOptions
              isOptionEqualToValue={(opt, value) => {
                return opt.id === value.id;
              }}
              onChange={(event, newValue) => {
                setSelectedInventory(newValue);
                setFilter([]);
                setProducts([]);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={t("inventory.select_inventory")}
                />
              )}
            />
          </Box>

          {selectedInventory && (
            <Box>
              <Box>
                <TextField
                  size="small"
                  sx={{ p: 1 }}
                  fullWidth
                  variant="outlined"
                  placeholder={t("layout.search_by_product_name")}
                  onChange={(event) => {
                    onChangeSearchField(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      search();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          search();
                        }}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>

              <Card
                variant="outlined"
                sx={{ m: 1, height: 300, overflow: "auto" }}
              >
                <List sx={{ m: 1 }}>
                  {products &&
                    products.map((product, index) => (
                      <ListItem key={index} divider disablePadding>
                        <ListItemButton
                          onClick={() => {
                            handleSelectdProduct(product);
                          }}
                        >
                          {product.title}
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Card>
              {selectdProduct && (
                <Box px={2} pt={2}>
                  <Divider />
                  <Chip
                    sx={{ my: 2, width: "100%" }}
                    label={selectdProduct.title}
                  />
                  <Divider />
                </Box>
              )}
              <Table sx={{ m: 1 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("layout.name")}</TableCell>
                    <TableCell>{t("inventory.current_stock")}</TableCell>
                    <TableCell>{t("layout.action")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{t("layout.remark")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectdProduct &&
                    selectdProduct.variants.map((variant, index) => (
                      <TableRow key={index}>
                        <TableCell>{getVariantName(variant) || "-"}</TableCell>
                        <TableCell>{variant.stock || 0}</TableCell>
                        <TableCell>
                          <Select
                            size="small"
                            value={variant.action}
                            onChange={(event, newValue) => {
                              console.log(event.target.value);

                              let newProduct = JSON.parse(
                                JSON.stringify(selectdProduct)
                              );
                              newProduct.variants[index] = {
                                ...variant,
                                action: event.target.value,
                              };
                              setSelectdProduct(newProduct);
                            }}
                          >
                            {stockActions.map((action, index) => (
                              <MenuItem key={index} value={action.value}>
                                {action.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            type={"number"}
                            value={variant.change || 0}
                            onChange={(event) => {
                              let qty = parseInt(event.target.value) || 0;
                              if (qty < 0) {
                                qty = 0;
                              }

                              let newProduct = JSON.parse(
                                JSON.stringify(selectdProduct)
                              );
                              newProduct.variants[index] = {
                                ...variant,
                                change: qty,
                              };
                              setSelectdProduct(newProduct);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            onChange={(event) => {
                              variant.remark = event.target.value;
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </CardContent>
      </Card>

      <ActionFooter onCancel={cancel} onSubmit={submit} />
    </Container>
  );
}
