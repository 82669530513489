import axios from "../helper/AxiosHelper";

export const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };
  // const response = await axios.get(
  //   `/product/query?query=${JSON.stringify(query)}&fields=all`
  // );
  const response = await axios.get(
    `/product/query?query=${JSON.stringify(query)}`
  );

  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        title: data.title,
        type: data.type,
        isAvailable: data.is_available,
        description: data.description,
        options: data.options,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const fullList = async (query) => {
  let url = "/product?fields=all";
  if (query) {
    url += `query?query=${JSON.stringify(query)}`;
  }
  const response = await axios.get(`/product?fields=all`);

  const result = response.data.result;
  return result.map((data) => {
    return {
      id: data.id,
      title: data.title,
      type: data.type,
      description: data.description,
      categories: data.categories,
      options: data.options,
      variants: data.variants.map((variant) => {
        const optionMapping = variant.option_values;
        let optionValues = [];
        for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
          if (attrValue && attrValue.id) {
            optionValues.push(attrValue.id);
          }
        }
        return {
          id: variant.id,
          productId: variant.product_id,
          sku: variant.sku,
          stock: variant.stock,
          price: variant.price,
          barcode: variant.barcode,
          optionValues: optionValues,
          optionMapping: variant.option_values,
        };
      }),
    };
  });
};

export const queryByStore = async (storeId, page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };
  const response = await axios.get(
    `/product/queryStore?query=${JSON.stringify(
      query
    )}&fields=all&store=${storeId}`
  );

  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        title: data.title,
        type: data.type,
        description: data.description,
        categories: data.categories,
        options: data.options,
        images: data.images,
        isSimple: data.is_simple,
        variants: data.variants.map((variant) => {
          const optionMapping = variant.option_values;
          let optionValues = [];
          for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
            if (attrValue && attrValue.id) {
              optionValues.push(attrValue.id);
            }
          }
          return {
            id: variant.id,
            productId: variant.product_id,
            sku: variant.sku,
            stock: variant.stock,
            price: variant.price,
            barcode: variant.barcode,
            optionValues: optionValues,
            optionMapping: variant.option_values,
            isAvailable: variant.is_available,
          };
        }),
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const details = async (id) => {
  const response = await axios.get(`product/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.record.id,
    type: result.record.type,
    isAvailable: result.record.is_available,
    title: result.record.title,
    basePrice: result.record.base_price,
    description: result.record.description,
    agreement: result.record.agreement_detail
      ? {
          id: result.record.agreement_detail.id,
          name: result.record.agreement_detail.name,
          consentForm: result.record.agreement_detail.consent_form,
        }
      : null,
    categories: result.record.categories,
    inventories: result.record.inventories,
    options: result.record.options,
    variants: result.record.variants.map((variant) => {
      const optionMapping = variant.option_values;
      let optionValues = [];
      for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
        if (attrValue && attrValue.id) {
          optionValues.push(attrValue.id);
        }
      }
      return {
        id: variant.id,
        productId: variant.product_id,
        sku: variant.sku,
        stock: variant.stock,
        price: variant.price,
        barcode: variant.barcode,
        optionValues: optionValues,
        optionMapping: variant.option_values,
        isAvailable: variant.is_available,
      };
    }),
    services: result.record.services
      ? result.record.services.map((service) => {
          return {
            id: service.id,
            variantId: service.variant_id,
            quota: service.quota,
            expireType: service.expire_type,
            expireDate: service.expire_date,
            expirePeriod: service.expire_period,
          };
        })
      : [],
    images: result.record.images,
    isSimple: result.record.is_simple,
    trackInventory: result.record.track_inventory,
  };
};

export const productOptionValueList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/productOptionValue/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const productOptionValueFullList = async () => {
  const response = await axios.get(`/productOptionValue`);
  const result = response.data.result;
  return result.map((data) => {
    return {
      id: data.id,
      name: data.name,
    };
  });
};

export const createProductOptionValue = async (value) => {
  var data = {
    name: value.name,
    addon_price: value.addonPrice,
    tagset: value.tagset.map((tag) => tag.id),
  };
  const response = await axios.post("/productOptionValue/create", data);

  return response.data.result.insert_id;
};

export const productOptionValueDetails = async (id) => {
  const response = await axios.get(`productOptionValue/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    addonPrice: result.record.addon_price,
    tagset: result.record.tagset_detail,
  };
};

export const updateProductOptionValue = async (id, value) => {
  var data = {
    name: value.name,
    addon_price: value.addonPrice,
    tagset: value.tagset.map((tag) => tag.id),
  };
  const response = await axios.post(`/productOptionValue/update/${id}`, data);

  return response.data.success;
};

export const createProductOption = async (option) => {
  var data = {
    name: option.name,
    values: option.values.map((value) => {
      return value.id;
    }),
  };
  const response = await axios.post("/productOption/create", data);

  return response.data.result.insert_id;
};

export const updateProductOption = async (id, option) => {
  var data = {
    name: option.name,
    values: option.values.map((value) => {
      return value.id;
    }),
    task_location: option.taskLocation,
  };
  const response = await axios.post(`/productOption/update/${id}`, data);

  return response.data.success;
};

export const productOptionList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/productOption/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const productOptionFullList = async () => {
  const response = await axios.get(`/productOption`);
  const result = response.data.result;
  return result.map((data) => {
    return {
      id: data.id,
      name: data.name,
      values: data.values.map((value) => {
        return {
          id: value.id,
          name: value.name,
          tagset: value.tagset_detail,
          addonPrice: value.addon_price,
        };
      }),
    };
  });
};

export const productOptionDetails = async (id) => {
  const response = await axios.get(`productOption/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    values: result.record.values,
    taskLocation: result.record.task_location,
  };
};

export const createProduct = async (product) => {
  var data = {
    ...product,
    is_simple: product.isSimple,
    track_inventory: product.trackInventory,
    agreement_id: product.agreement ? product.agreement.id : null,
    categories: product.categories.map((category) => category.id),
    inventories: product.inventories.map((inventory) => inventory.id),
    base_price: product.basePrice,
    variants: product.variants.map((variant) => {
      let addonPrice = 0;
      if (variant.optionMapping) {
        for (const [key, value] of Object.entries(variant.optionMapping)) {
          addonPrice += value.addonPrice || 0;
        }
      }

      return {
        temp_id: variant.tempId,
        sku: variant.sku,
        barcode: variant.barcode,
        stock: variant.stock,
        price: parseFloat(product.basePrice) + addonPrice,
        option_values: variant.optionValues,
        is_available: variant.isAvailable,
      };
    }),
    services: product.services.map((service) => {
      return {
        id: service.id,
        temp_id: service.tempId,
        quota: service.quota,
        description: service.description,
        expire_type: service.expireType,
        expire_period: service.expirePeriod,
        expire_date: service.expireDate,
        remark: service.remark,
      };
    }),
    images: product.images || [],
    input_image: product.inputImage,
    inputImage: undefined,
  };
  const response = await axios.post("/product/create", data);

  return response.data.result.insert_id;
};

export const updateProduct = async (product) => {
  var data = {
    ...product,
    is_simple: product.isSimple,
    is_available: product.isAvailable,
    track_inventory: product.trackInventory,
    agreement_id: product.agreement ? product.agreement.id : null,
    categories: product.categories.map((category) => category.id),
    inventories: product.inventories.map((inv) => inv.id),
    base_price: product.basePrice,
    variants: product.variants.map((variant) => {
      let addonPrice = 0;
      for (const [key, value] of Object.entries(variant.optionMapping)) {
        addonPrice += value.addonPrice || 0;
      }

      return {
        temp_id: variant.tempId,
        id: variant.id ? variant.id : undefined,
        sku: variant.sku,
        barcode: variant.barcode,
        stock: variant.stock,
        price: parseFloat(product.basePrice) + addonPrice,
        option_values: variant.optionValues,
        is_available: variant.isAvailable,
      };
    }),
    services: product.services.map((service) => {
      return {
        id: service.id,
        temp_id: service.tempId,
        quota: service.quota,
        description: service.description,
        expire_type: service.expireType,
        expire_period: service.expirePeriod,
        expire_date: service.expireDate,
        remark: service.remark,
      };
    }),
    images: product.images || [],
    input_image: product.inputImage,
    inputImage: undefined,
    isAvailable: undefined,
    isSimple: undefined,
    trackInventory: undefined,
    agreement: undefined,
  };
  const response = await axios.post(`/product/update/${product.id}`, data);

  return response.data.success;
};

export const deleteProduct = async (id) => {
  const response = await axios.post(`product/delete/${id}`);
  return response.data.success;
};

export const getAddonTagSet = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };
  const response = await axios.get(
    `/addonTagSet/query?query=${JSON.stringify(query)}&fields=all`
  );

  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        tags: data.tag_list,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const createAddonTagSet = async (addonTagSet) => {
  var data = {
    name: addonTagSet.name,
    tag_list: addonTagSet.tags,
  };
  const response = await axios.post("/addonTagSet/create", data);

  return response.data.result.insert_id;
};

export const AddonTagSetDetails = async (id) => {
  const response = await axios.get(`addonTagSet/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    tags: result.record.tag_list,
  };
};

export const updateAddonTagSet = async (id, addonTagSet) => {
  var data = {
    name: addonTagSet.name,
    tag_list: addonTagSet.tags,
  };
  const response = await axios.post(`/addonTagSet/update/${id}`, data);

  return response.data.success;
};

export const deleteAddonTagSet = async (id) => {
  const response = await axios.post(`addonTagSet/delete/${id}`);
  return response.data.success;
};
