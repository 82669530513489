import AuthProvider from "../components/AuthProvider";
import { getUserPageName } from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";
import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";

const Users = loadable({
  loader: () => import("../views/user/List"),
  loading: Loading,
});
const Edit = loadable({
  loader: () => import("../views/user/Edit"),
  loading: Loading,
});
const Create = loadable({
  loader: () => import("../views/user/Create"),
  loading: Loading,
});

const UsersRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/users",
      element: (
        <AuthProvider page={getUserPageName()} action={"view"}>
          <Users />
        </AuthProvider>
      ),
    },
    {
      path: "users/:id",
      element: (
        <AuthProvider page={getUserPageName()} action={"view"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "users/create",
      element: (
        <AuthProvider page={getUserPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default UsersRoute;
