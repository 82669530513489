import Components from "./components";
import Palette from "./palette.js";
import Typography from "./typography.js";

//console.log(Components);

const themeOptions = {
    palette: Palette,
    typography: Typography,
    components: Components,
    // spacing: factor => `${factor >= 1 ? Math.pow(1.125, factor) : factor}rem`,
};

export default themeOptions;
