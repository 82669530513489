import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Skeleton,
  Box,
  Grid,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Button,
  Link,
} from "@mui/material";
import FileUploader from "../FileUploader";
import { t } from "i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReactSwal from "../../helper/AlertHelper";
import { convertBase64 } from "../../helper/UnitsHelper";
import { useSelector } from "react-redux";
import { user } from "../../reducers/userSlice";
import { pageName } from "../../reducers/commonSlice";
import { checkPermission } from "../../helper/PermissionHelper";

export default function AgreementInfo({
  agreement,
  setAgreement,
  deleteAgreement,
}) {
  const [images, setImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const open = Boolean(anchorEl);
  useEffect(() => {}, []);

  useEffect(() => {
    const deletePermission = checkPermission(
      currentUser,
      currentPage,
      "delete"
    );

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const onFileChange = async (files) => {
    const file = files[0];

    const base64 = await convertBase64(file);

    console.log(base64);
    setAgreement({ ...agreement, consentFormPdf: base64 });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAgreement();
      }
    });
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          title={t("settings.agreement")}
          action={
            deleteAgreement && haveDeletePermission ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={showDeleteConfirm}>
                    <Typography color={"error"}>
                      {t("layout.delete")}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box />
            )
          }
        />
        <CardContent>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {agreement.consentForm ? (
              <Box display={"flex"} flexDirection={"column"}>
                <Link href={agreement.consentForm} target={"_blank"}>
                  {t("agreement.preview_agreement")}
                </Link>
                <Button
                  size="small"
                  onClick={() => {
                    setAgreement({
                      ...agreement,
                      consentForm: null,
                    });
                  }}
                >
                  {t("layout.delete")}
                </Button>
              </Box>
            ) : (
              <FileUploader
                onFileChange={onFileChange}
                accept={"application/pdf"}
              />
            )}
            <TextField
              fullWidth
              label={t("layout.name")}
              value={agreement.name}
              onChange={(event) => {
                setAgreement({
                  ...agreement,
                  name: event.target.value,
                });
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
