import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const arrayCompare = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  ) {
    return false;
  }

  // .concat() to not mutate arguments
  const arr1 = _arr1.concat();
  const arr2 = _arr2.concat();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace("/" + param, ""),
    location.pathname
  );
};

export const getVariantName = (item) => {
  const optionMapping = item.optionMapping;
  const optionData = item.optionData;
  let name = "";
  for (const [attr, values] of Object.entries(optionMapping)) {
    if (attr == 1) return "";
    name += `${values.option_name}:${values.name} \n`;
    if (optionData && optionData.length > 0) {
      const option = optionData.find((o) => o.optionValueId == values.id);
      if (option) {
        name += ` (${option.tags.map((tag) => {
          return `${tag.name}: ${tag.value}`;
        })}) `;
      }
    }
  }
  return name;
};

export const useStrictDroppable = (loading) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation;

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

export const timeout = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
