import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import {
  getAgreementPageName,
  getDeviceLockPageName,
  getSettingsPageName,
} from "../helper/GlobalValueHelper";
import AgreementList from "../views/agreement/List";
import AgreementCreate from "../views/agreement/Create";
import AgreementEdit from "../views/agreement/Edit";

const Settings = loadable({
  loader: () => import("../views/settings/List"),
  loading: Loading,
});
const DeviceLock = loadable({
  loader: () => import("../views/settings/DeviceLock"),
  loading: Loading,
});

const SettingsRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/settings",
      element: (
        <AuthProvider page={getSettingsPageName()} action={"view"}>
          <Settings />
        </AuthProvider>
      ),
    },
    {
      path: "/settings/device-lock",
      element: (
        <AuthProvider page={getDeviceLockPageName()} action={"view"}>
          <DeviceLock />
        </AuthProvider>
      ),
    },
    {
      path: "/settings/agreement",
      element: (
        <AuthProvider page={getAgreementPageName()} action={"view"}>
          <AgreementList />
        </AuthProvider>
      ),
    },
    {
      path: "/settings/agreement/create",
      element: (
        <AuthProvider page={getAgreementPageName()} action={"create"}>
          <AgreementCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/settings/agreement/:id",
      element: (
        <AuthProvider page={getAgreementPageName()} action={"view"}>
          <AgreementEdit />
        </AuthProvider>
      ),
    },
  ],
};

export default SettingsRoute;
