import { useEffect, useState } from "react";
import { Container, Box, Breadcrumbs, Typography } from "@mui/material";
import axios from "../../helper/AxiosHelper";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation, Translation } from "react-i18next";
import * as CategoryApi from "../../api/CategoryApi";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import PaymentMethodInfo from "../../components/payment/PaymentMethodInfo";
import { AgreementApi } from "../../api/AgreementApi";
import AgreementInfo from "../../components/agreement/AgreementInfo";

export default function AgreementCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [agreement, setAgreement] = useState({
    name: "",
    consentFormPdf: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {}, []);

  const onSubmit = async () => {
    try {
      setFullScreenLoading(true);

      if (!agreement.name || !agreement.consentFormPdf) {
        toast.error(t("layout.please_enter_all_required_fields"));
        setFullScreenLoading(false);
        return;
      }

      const id = await AgreementApi.create(agreement);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/settings/agreement/${id}`);
      }

      setFullScreenLoading(false);
    } catch (error) {
      setFullScreenLoading(false);
      console.log(error);
    }
  };

  const onCancel = async () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={"/payment-method"}>{t("menu.settings")}</Link>
        <Link to={"/settings/agreement"}>{t("settings.agreement")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <AgreementInfo agreement={agreement} setAgreement={setAgreement} />
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={onSubmit} />
    </Container>
  );
}
