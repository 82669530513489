import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./reducers/commonSlice";
import userReducer from "./reducers/userSlice";
import shoppingCartSlice from "./reducers/shoppingCartSlice";

export const store = configureStore({
  reducer: {
    common: commonReducer,
    user: userReducer,
    shoppingCart: shoppingCartSlice,
  },
});
