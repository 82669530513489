import axios from "../helper/AxiosHelper";

export const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/inventory/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const details = async (id) => {
  const response = await axios.get(`inventory/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
  };
};

export const update = async (id, inventory) => {
  const data = {
    id: inventory.id,
    name: inventory.name,
  };

  const response = await axios.post(`inventory/update/${id}`, data);
  return response.data.success;
};

export const create = async (inventory) => {
  const data = {
    name: inventory.name,
  };

  const response = await axios.post(`inventory/create`, data);

  return response.data.result.insert_id;
};

export const stockBulkChange = async (item) => {
  console.log(item);
  const data = {
    inventory_id: item.inventoryId,
    changes: item.changes.map((change) => {
      return {
        variant_id: change.variantId,
        qty_change: change.qtyChange || 0,
        remark: change.remark || "",
      };
    }),
  };

  const response = await axios.post(`stock/bulkChange`, data);
  return response.data.success;
};

export const stockQuery = async (inventoryId, page, pageSize, filters) => {
  const query = {
    inventory_id: inventoryId,
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.post(
    `/stock/query?query=${JSON.stringify(query)}&fields=all`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        title: data.title,
        description: data.description,
        categories: data.categories,
        options: data.options,
        variants: data.variants.map((variant) => {
          const optionMapping = variant.option_values;
          let optionValues = [];
          for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
            optionValues.push(attrValue.id);
          }
          return {
            id: variant.id,
            productId: variant.product_id,
            sku: variant.sku,
            stock: variant.stock,
            price: variant.price,
            barcode: variant.barcode,
            optionValues: optionValues,
            optionMapping: variant.option_values,
          };
        }),
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const inventoryStockHistory = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC"
  };
  const response = await axios.post(
    `/inventoryStockHistory/query?query=${JSON.stringify(query)}&fields=all`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        stockTime: data.stock_time,
        variantId: data.variant_id,
        qtyChange: data.qty_change,
        remark: data.remark,
        variant: data.variant
          ? {
              productName: data.variant.product_name,
              optionValues: data.variant.option_values,
            }
          : null,
        createdAt: data.created_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const productStock = async (storeId, variants) => {
  const response = await axios.get(
    `/stock/productStock?store_id=${storeId}&variants=${JSON.stringify(
      variants
    )}`
  );
  const result = response.data.result;

  return result.map((data) => {
    return {
      variantId: data.variant_id,
      stock: data.stock,
    };
  });
};
