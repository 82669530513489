import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";

const Login = loadable({
  loader: () => import("../views/Login"),
  loading: Loading,
});

const ServiceSign = loadable({
  loader: () => import("../views/member/ServiceSign"),
  loading: Loading,
});

const UnverifiedRoute = {
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/service-sign",
      element: <ServiceSign />,
    },
  ],
};

export default UnverifiedRoute;
