import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Skeleton,
  Box,
  Grid,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import ImageUploader from "../ImageUploader";
import { t } from "i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReactSwal from "../../helper/AlertHelper";
import { useSelector } from "react-redux";
import { user } from "../../reducers/userSlice";
import { pageName } from "../../reducers/commonSlice";
import { checkPermission } from "../../helper/PermissionHelper";
import { toast } from "react-toastify";

export default function PaymentMethodInfo({
  paymentMethod,
  setPaymentMethod,
  deletePaymentMethod,
}) {
  const [images, setImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  useEffect(() => {}, []);

  useEffect(() => {
    const deletePermission = checkPermission(
      currentUser,
      currentPage,
      "delete"
    );

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const onImageChange = (images) => {
    if (images && images.length > 0) {
      let image = images[0];
      if (image.file.size > 1024 * 1024) {
        toast.error("Image size must be less than 1MB");
        return;
      }
      setPaymentMethod({ ...paymentMethod, iconFile: image.dataUrl });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deletePaymentMethod();
      }
    });
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          title={t("layout.payment_method")}
          action={
            deletePaymentMethod && haveDeletePermission ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={showDeleteConfirm}>
                    <Typography color={"error"}>
                      {t("layout.delete")}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box />
            )
          }
        />
        <CardContent>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {paymentMethod.icon ? (
              <Box display={"flex"} flexDirection={"column"}>
                <Box
                  sx={{ height: 100, p: 1 }}
                  component={"img"}
                  src={paymentMethod.icon}
                />
                <Button
                  size="small"
                  onClick={() => {
                    setPaymentMethod({
                      ...paymentMethod,
                      icon: null,
                    });
                  }}
                >
                  {t("layout.delete")}
                </Button>
              </Box>
            ) : (
              <ImageUploader
                maxNumber={1}
                exitingImages={images}
                maxFileSize={1024 * 1024}
                onImageChange={onImageChange}
              />
            )}
            <TextField
              fullWidth
              label={t("layout.name")}
              value={paymentMethod.name}
              onChange={(event) => {
                setPaymentMethod({
                  ...paymentMethod,
                  name: event.target.value,
                });
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
