import Container from "@mui/material/Container";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MetaHeader from "./components/MetaHeader";
import DashboardLayout from "./components/DashboardLayout";
import { getLocalStorageLocale } from "./helper/LocalStorageHelper";
import Router from "./router";

export default function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = getLocalStorageLocale();
    i18n.changeLanguage(lang);
  }, []);

  return (
    <MetaHeader>
      <Container disableGutters maxWidth={false}>
        <Router></Router>
      </Container>
    </MetaHeader>
  );
}
