import Palette from "../palette.js";

const effect =
  "box-shadow 0.2s ease-in-out, margin-top 0.2s ease-in-out, opacity 0.2s ease-in-out";
const Element = {
  variants: [
    {
      props: { variant: "contained" },
      style: {
        color: Palette.white.main,
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        borderColor: "#e5e7eb",
      },
    },
  ],
  styleOverrides: {
    root: {
      // padding: "1rem 0.8rem",
      // whiteSpace: "nowrap",
      // boxShadow: "none",
    },
  },
};

export default Element;
