import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import AuthProvider from "../components/AuthProvider";
import {
  getStorePageName,
  getTablePageName,
} from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";

const TableList = loadable({
  loader: () => import("../views/table/List"),
  loading: Loading,
});
const TableEdit = loadable({
  loader: () => import("../views/table/Edit"),
  loading: Loading,
});
const TableCreate = loadable({
  loader: () => import("../views/table/Create"),
  loading: Loading,
});

const StoreRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/tables",
      element: (
        <AuthProvider page={getTablePageName()} action={"view"}>
          <TableList />
        </AuthProvider>
      ),
    },
    {
      path: "/tables/:id",
      element: (
        <AuthProvider page={getTablePageName()} action={"view"}>
          <TableEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/tables/create",
      element: (
        <AuthProvider page={getTablePageName()} action={"create"}>
          <TableCreate />
        </AuthProvider>
      ),
    },
  ],
};

export default StoreRoute;
