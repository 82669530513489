import loadable from "@react-loadable/revised";
import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import Loading from "../components/Loading";
import { getBookingPageName } from "../helper/GlobalValueHelper";

const Booking = loadable({
  loader: () => import("../views/booking/Calendar"),
  loading: Loading,
});

const BookingRoute = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "/booking",
      element: (
        <AuthProvider page={getBookingPageName()} action={"view"}>
          <Booking />
        </AuthProvider>
      ),
    },
  ],
};

export default BookingRoute;
