import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Skeleton,
  Box,
  Grid,
} from "@mui/material";

export default function CategorySkeleton({ row, colSpan }) {
  const [skeletonList, setSkeletonList] = useState([]);

  useEffect(() => {}, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
        ></CardHeader>
        <CardContent>
          <Skeleton height={40} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </CardContent>
      </Card>
    </Box>
  );
}
