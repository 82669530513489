import palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      "& .MuiTableCell-head": {
        color: palette.text.secondary,
        paddingBottom: 0,
      },
    },
  },
};

export default Element;
