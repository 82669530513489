import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { checkPermission } from "../helper/PermissionHelper";
import { menuOpenState } from "../reducers/commonSlice";
import { user } from "../reducers/userSlice";

// icon
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";
import SellIcon from "@mui/icons-material/Sell";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import ViewListIcon from "@mui/icons-material/ViewList";

import { Typography } from "@mui/material";
import {
  getBookingPageName,
  getCategoryPageName,
  getDashboardPageName,
  getInventoryPageName,
  getMemberPageName,
  getOrderPageName,
  getProductPageName,
  getPromotionPageName,
  getReportPageName,
  getSettingsPageName,
  getStorePageName,
  getTablePageName,
} from "../helper/GlobalValueHelper";

export default function NestedList() {
  const { t } = useTranslation();
  const location = useLocation();
  const menuState = useSelector(menuOpenState);
  const currentUser = useSelector(user);

  // note: keep use <Translation> to avoid not rendering when language changed
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    setMenuList([
      {
        icon: <HomeIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.dashboard")} </Box>}
          </Translation>
        ),
        path: "/",
        havePermission: checkPermission(
          currentUser,
          getDashboardPageName(),
          "view"
        ),
      },
      {
        icon: <ViewListIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.orders")} </Box>}
          </Translation>
        ),
        path: "/orders",
        havePermission: checkPermission(
          currentUser,
          getOrderPageName(),
          "view"
        ),
      },
      {
        icon: <CategoryIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.products")} </Box>}
          </Translation>
        ),
        path: "/products",
        havePermission: checkPermission(
          currentUser,
          getProductPageName(),
          "view"
        ),
      },
      {
        icon: <StoreIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.stores")} </Box>}
          </Translation>
        ),
        path: "/stores",
        havePermission: checkPermission(
          currentUser,
          getStorePageName(),
          "view"
        ),
      },
      {
        icon: <AccountTreeIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.category")} </Box>}
          </Translation>
        ),
        path: "/category",
        havePermission: checkPermission(
          currentUser,
          getCategoryPageName(),
          "view"
        ),
      },
      {
        icon: <PeopleIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.members")} </Box>}
          </Translation>
        ),
        path: "/members",
        havePermission: checkPermission(
          currentUser,
          getMemberPageName(),
          "view"
        ),
      },
      {
        icon: <SellIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.promotions")} </Box>}
          </Translation>
        ),
        path: "/promotions",
        havePermission: checkPermission(
          currentUser,
          getPromotionPageName(),
          "view"
        ),
      },
      {
        icon: <InventoryIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.inventory")} </Box>}
          </Translation>
        ),
        path: "/inventory",
        havePermission: checkPermission(
          currentUser,
          getInventoryPageName(),
          "view"
        ),
      },
      {
        icon: <CalendarMonthIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.booking")} </Box>}
          </Translation>
        ),
        path: "/booking",
        havePermission: checkPermission(
          currentUser,
          getBookingPageName(),
          "view"
        ),
      },
      {
        icon: <AssessmentIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.report")} </Box>}
          </Translation>
        ),
        path: "/report",
        havePermission: checkPermission(
          currentUser,
          getReportPageName(),
          "view"
        ),
      },
      {
        icon: <TableRestaurantIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.table")} </Box>}
          </Translation>
        ),
        path: "/tables",
        havePermission: checkPermission(
          currentUser,
          getTablePageName(),
          "view"
        ),
      },
      {
        icon: <SettingsIcon />,
        name: (
          <Translation>
            {(t, { i18n }) => <Box> {t("menu.settings")} </Box>}
          </Translation>
        ),
        path: "/settings",
        havePermission: checkPermission(
          currentUser,
          getSettingsPageName(),
          "view"
        ),
      },
    ]);
  }, [currentUser]);

  const handleClick = (index) => {
    menuList[index].open = !menuList[index].open;
    setMenuList([...menuList]);
  };

  // check is current path is match with menu path
  const isMatch = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          flex: 1,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Divider></Divider>
          </ListSubheader>
        }
      >
        {menuList.map((list, index) =>
          list.havePermission ? (
            list.sub ? (
              <Box key={index}>
                <ListItemButton dense onClick={() => handleClick(index)}>
                  <ListItemIcon>{list.icon}</ListItemIcon>
                  <ListItemText primary={list.name} />
                  {list.open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={list.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {list.sub.map((sub, subIndex) => (
                      <ListItemButton
                        dense
                        key={`${subIndex}`}
                        component={Link}
                        sx={menuState ? { pl: 4 } : {}}
                        to={sub.path}
                        selected={isMatch(sub.path)}
                      >
                        <ListItemIcon>{sub.icon}</ListItemIcon>
                        <ListItemText primary={sub.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Box>
            ) : (
              <ListItemButton
                dense
                key={index}
                component={Link}
                to={list.path}
                selected={location.pathname === list.path}
              >
                <ListItemIcon>{list.icon}</ListItemIcon>
                <ListItemText primary={list.name} />
              </ListItemButton>
            )
          ) : null
        )}
      </List>
      <Typography
        variant="caption"
        sx={{ textAlign: "center", p: 1 }}
        color={"gray"}
      >
        {t("layout.version")} : {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  );
}
