import { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImageUploading from "react-images-uploading";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ImageUploader({
  onImageChange,
  maxNumber,
  maxFileSize,
}) {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    onImageChange(imageList);
  };

  const onDragEnd = (result) => {
    const items = reorder(
      images,
      result.source.index,
      result.destination.index
    );

    setImages(items);
    onImageChange(items);
  };

  return (
    <Box component={"div"} className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="dataUrl"
        maxFileSize={maxFileSize}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          errors,
          dragProps,
        }) => (
          // write your building UI
          <Box components={"div"} className="upload__image-wrapper">
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable droppableId="droppableId" direction="horizontal">
                {(provided) => (
                  <Grid
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    container
                    spacing={2}
                  >
                    {imageList.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`draggableId${index}`}
                        index={index}
                      >
                        {(p) => (
                          <Grid
                            item
                            ref={p.innerRef}
                            {...p.draggableProps}
                            {...p.dragHandleProps}
                            className="image-item"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={image["dataUrl"]}
                              alt=""
                              maxHeight={200}
                              maxWidth={"100%"}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center",
                              }}
                              component={"div"}
                              className="image-item__btn-wrapper"
                            >
                              <Button onClick={() => onImageUpdate(index)}>
                                {t("layout.update")}
                              </Button>
                              <Button onClick={() => onImageRemove(index)}>
                                {t("layout.remove")}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
            {images.length < maxNumber && (
              <Button
                fullWidth
                variant="outlined"
                color={isDragging ? "warning" : "primary"}
                sx={{ p: 10, mt: 2 }}
                onClick={onImageUpload}
                {...dragProps}
              >
                {t("layout.click_or_drop_here")}
              </Button>
            )}
            {/* <Button onClick={onImageRemoveAll}>Remove all images</Button> */}
            {errors && (
              <Box>
                {errors.maxNumber && (
                  <Typography color={"error"}>
                    Number of selected images exceed maxNumber
                  </Typography>
                )}
                {errors.acceptType && (
                  <Typography color={"error"}>
                    Your selected file type is not allow
                  </Typography>
                )}
                {errors.maxFileSize && (
                  <Typography color={"error"}>
                    Image size must be less than 1MB
                  </Typography>
                )}
                {errors.resolution && (
                  <Typography color={"error"}>maxWidth </Typography>
                )}
              </Box>
            )}
          </Box>
        )}
      </ImageUploading>
    </Box>
  );
}
