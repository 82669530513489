import Palette from "../palette.js";
import Typography from "../typography.js";

const Element = {
  styleOverrides: {
    root: {
      paddingBottom: "8px",
    },
  },
};

export default Element;
