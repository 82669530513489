import loadable from "@react-loadable/revised";
import AuthProvider from "../components/AuthProvider";
import Loading from "../components/Loading";
import { getPosPageName } from "../helper/GlobalValueHelper";

const Home = loadable({
  loader: () => import("../views/pos/Home"),
  loading: Loading,
});

const Login = loadable({
  loader: () => import("../views/pos/Login"),
  loading: Loading,
});

const PosRoute = {
  children: [
    {
      path: "/pos/login",
      element: <Login />,
    },
    {
      path: "/pos",
      element: (
        <AuthProvider page={getPosPageName()} action={"view"}>
          <Home />
        </AuthProvider>
      ),
    },
  ],
};

export default PosRoute;
