export const setLocalStorageLocale = (lang) => {
  localStorage.setItem("lang", lang);
};

export const getLocalStorageLocale = () => {
  return localStorage.getItem("lang");
};

export const setLocalStorageUser = (userInfo) => {
  if (!userInfo) return;
  localStorage.setItem(
    "user",
    JSON.stringify({
      ...userInfo,
      // for protect user data and avoid user change the permission
      // do not store plain text locally, these will fetch from server and store in redux
      permissions: undefined,
      roles: undefined,
      email: undefined,
      username: undefined,
    })
  );
};

export const getLocalStorageUser = () => {
  return localStorage.getItem("user");
};

export const removeLocalStorageUser = () => {
  localStorage.removeItem("user");
};

export const getDeviceSecretKey = () => {
  return localStorage.getItem("DEVICE_SECRET_KEY");
};

export const setDeviceSecretKey = () => {
  const key = process.env.REACT_APP_DEVICE_SECRET_KEY;
  localStorage.setItem("DEVICE_SECRET_KEY", key);
};

export const removeDeviceSecretKey = () => {
  localStorage.removeItem("DEVICE_SECRET_KEY");
};
