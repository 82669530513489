import Palette from "../palette.js";

const Element = {
  // variants: [
  //     {
  //         props: { require },
  //         style: { color: "#fff" },
  //     },
  // ],
  styleOverrides: {
    root: {
      // padding: 0,
    },
  },
};

export default Element;
