import { useEffect, useState } from "react";
import { Box, Button, Paper } from "@mui/material";
import { useTranslation, Translation } from "react-i18next";
import { useSelector } from "react-redux";
import { pageName, actionName } from "../reducers/commonSlice";
import { user } from "../reducers/userSlice";
import { checkPermission } from "../helper/PermissionHelper";

export default function ActionFooter({ onSubmit, onCancel }) {
  const [haveEditPermission, setHaveEditPermission] = useState(false);
  const currentPage = useSelector(pageName);
  const currentAction = useSelector(actionName);
  const currentUser = useSelector(user);

  useEffect(() => {
    const permission = checkPermission(currentUser, currentPage, "update");
    setHaveEditPermission(permission);
  }, [currentUser, currentPage, currentAction]);

  return (
    <Box
      component={Paper}
      sx={{
        position: "fixed",
        right: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "end",
      }}
    >
      <Button
        sx={{ py: 1.5, px: 3, m: 1 }}
        size="large"
        variant="outlined"
        onClick={onCancel}
      >
        <Translation>{(t) => <>{t("layout.cancel")}</>}</Translation>
      </Button>
      {haveEditPermission && (
        <Button
          sx={{ py: 1.5, px: 3, m: 1 }}
          disableElevation
          variant="contained"
          onClick={onSubmit}
        >
          <Translation>{(t) => <>{t("layout.save")}</>}</Translation>
        </Button>
      )}
    </Box>
  );
}
