import { DateRange } from "@mui/icons-material";
import axios from "../helper/AxiosHelper";

const postLogin = async (email, password) => {
  const data = {
    email: email,
    password: password,
  };

  const response = await axios.post("/login", data);
  return response;
};

const logout = async () => {
  const data = {};

  const response = await axios.post("/logout", data);
  return response;
};

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/user/query?query=${JSON.stringify(query)}`
  );

  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        username: data.username,
        email: data.email,
        role: data.role,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async (id) => {
  const response = await axios.get(`user/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    username: result.record.username,
    email: result.record.email,
    phoneNumber: result.record.phone_number,
    firstName: result.record.first_name,
    lastName: result.record.last_name,
    phoneNumber: result.record.phone_number,
    joinDate: result.record.join_date,
    roles: result.record.roles,
    permissions: result.record.permission,
  };
};

const create = async (user) => {
  const data = {
    username: user.username,
    first_name: user.firstName,
    last_mame: user.lastName,
    email: user.email,
    password: user.password != "" ? user.password : undefined,
    phone_number: user.phoneNumber,
    roles: user.roles ? user.roles.map((role) => role.value) : [],
  };

  const response = await axios.post(`user/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, user) => {
  const data = {
    username: user.username,
    first_name: user.firstName,
    last_mame: user.lastName,
    email: user.email,
    password: user.password != "" ? user.password : undefined,
    phone_number: user.phoneNumber,
    join_date: user.joinDate,
    roles: user.roles ? user.roles.map((role) => role.value) : [],
  };

  const response = await axios.post(`user/update/${id}`, data);

  return response.data.success;
};

export const deleteUser = async (id) => {
  const response = await axios.post(`user/delete/${id}`);
  return response.data.success;
};

export const UserApi = {
  postLogin,
  logout,
  list,
  details,
  create,
  update,
  deleteUser,
};
