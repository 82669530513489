import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { updateUserInfo, updateJwtState } from "../reducers/userSlice";
import { updatePageState, updateActionState } from "../reducers/commonSlice";

// helper
import {
  getLocalStorageUser,
  removeLocalStorageUser,
} from "../helper/LocalStorageHelper";
import {
  checkDeviceLock,
  checkPagePermission,
} from "../helper/PermissionHelper";
import { UserApi } from "../api/UserApi";

const AuthProvider = ({ children, page, action }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(updatePageState(page));
    dispatch(updateActionState(action));

    var user = getLocalStorageUser();
    if (!user) {
      setIsAuthenticated(false);
      return;
    }

    try {
      user = JSON.parse(user);
      dispatch(updateJwtState(user.jwt));
      fetchUser(user.userId);
    } catch (error) {
      console.log(error);
      removeLocalStorageUser();
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    chekcUser();
    checkDeviceLock(user);
    checkPagePermission(user, page, action, navigate);
    dispatch(updatePageState(page));
    dispatch(updateActionState(action));
  }, [location]);

  const fetchUser = async (id) => {
    setIsLoading(true);
    try {
      const response = await UserApi.details(id);
      if (response) {
        setUser(response);
        dispatch(updateUserInfo(response));
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(true);
      }
    } catch (err) {
      console.error(err);
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  const chekcUser = () => {
    var user = getLocalStorageUser();
    if (!user) {
      setIsAuthenticated(false);
      return;
    }
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default AuthProvider;
