import loadable from "@react-loadable/revised";
import AuthProvider from "../components/AuthProvider";
import { getCategoryPageName } from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";
import Loading from "../components/Loading";

const CategoryList = loadable({
  loader: () => import("../views/category/List"),
  loading: Loading,
});
const CategoryEdit = loadable({
  loader: () => import("../views/category/Edit"),
  loading: Loading,
});
const CategoryCreate = loadable({
  loader: () => import("../views/category/Create"),
  loading: Loading,
});

const CategoryRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/category",
      element: (
        <AuthProvider page={getCategoryPageName()} action={"view"}>
          <CategoryList />
        </AuthProvider>
      ),
    },
    {
      path: "/category/:id",
      element: (
        <AuthProvider page={getCategoryPageName()} action={"view"}>
          <CategoryEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/category/create",
      element: (
        <AuthProvider page={getCategoryPageName()} action={"create"}>
          <CategoryCreate />
        </AuthProvider>
      ),
    },
  ],
};

export default CategoryRoute;
