import { useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { convertBase64 } from "../helper/UnitsHelper";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
export default function FileUploader({ onFileChange, multiple, accept }) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  const handleFileChange = async (e) => {
    const filesObj = e.target.files;
    const filesTmp = [];
    for (const [key, value] of Object.entries(filesObj)) {
      filesTmp.push(value);
    }
    setFiles(filesTmp);

    onFileChange(filesObj);
  };

  const removeFile = (index) => {
    const newArr = files.filter((item, i) => i !== index);
    setFiles(newArr);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {files && files.length > 0 ? (
        <Box>
          {files.map((file, index) => (
            <Box key={index} display={"flex"} alignItems={"center"}>
              <Typography>{file.name}</Typography>
              <IconButton
                onClick={() => {
                  removeFile(index);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : (
        <Button variant="outlined" component="label">
          {t("layout.upload_file")}
          <Box
            component={"input"}
            hidden
            accept={accept}
            type="file"
            onChange={handleFileChange}
            multiple={multiple}
          />
        </Button>
      )}
    </Box>
  );
}
