import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import AuthProvider from "../components/AuthProvider";
import { getStorePageName } from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";

const StoreList = loadable({
  loader: () => import("../views/store/List"),
  loading: Loading,
});
const StoreEdit = loadable({
  loader: () => import("../views/store/Edit"),
  loading: Loading,
});
const StoreCreate = loadable({
  loader: () => import("../views/store/Create"),
  loading: Loading,
});

const StoreRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/stores",
      element: (
        <AuthProvider page={getStorePageName()} action={"view"}>
          <StoreList />
        </AuthProvider>
      ),
    },
    {
      path: "/stores/:id",
      element: (
        <AuthProvider page={getStorePageName()} action={"view"}>
          <StoreEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/stores/create",
      element: (
        <AuthProvider page={getStorePageName()} action={"create"}>
          <StoreCreate />
        </AuthProvider>
      ),
    },
  ],
};

export default StoreRoute;
