import loadable from "@react-loadable/revised";
import Loading from "../components/Loading";
import AuthProvider from "../components/AuthProvider";
import { getMemberPageName } from "../helper/GlobalValueHelper";
import DashboardLayout from "../components/DashboardLayout";

const List = loadable({
  loader: () => import("../views/member/List"),
  loading: Loading,
});
const Edit = loadable({
  loader: () => import("../views/member/Edit"),
  loading: Loading,
});
const Create = loadable({
  loader: () => import("../views/member/Create"),
  loading: Loading,
});

const MemberRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/members",
      element: (
        <AuthProvider page={getMemberPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/members/:id",
      element: (
        <AuthProvider page={getMemberPageName()} action={"view"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/members/create",
      element: (
        <AuthProvider page={getMemberPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default MemberRoute;
